import styled from "styled-components";

export const Card = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
  padding: 0.5rem;
  border: 0.125rem solid var(--gray-900);
  border-radius: 0.25rem;
  transition: background-color 0.2s;

  &.selected {
    background-color: var(--blue-500);

    h4 {
      color: #fff;
    }
  }
`;

export const GuestCard = styled.div`
  min-height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
  padding: 0.5rem;
  border: 0.125rem solid var(--gray-900);
  border-radius: 0.25rem;
  transition: background-color 0.2s;

  &.selected {
    background-color: var(--blue-500);

    h4 {
      color: #fff;
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;

  figure {
    margin-right: 1rem;
  }
`;

export const UserTextData = styled.div`
  flex: 1;

  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 1.125rem;
    color: var(--blue-500);
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 0.9rem;

    span {
      font-weight: bold;
    }
  }
`;

export const ButtonsContainer = styled.div`
  padding-top: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;
