import { MdCheck } from "react-icons/md";
import { StyledCheckbox } from "./styles";

interface CheckboxProps {
  id: string;
  checked: boolean;
  setChecked: () => void;
  label: string;
}

export default function Checkbox({
  id,
  checked,
  setChecked,
  label,
}: CheckboxProps) {
  return (
    <>
      <input
        style={{ display: "none" }}
        type="checkbox"
        id={id}
        onChange={() => setChecked()}
      />
      <label style={{ display: "flex", margin: ".5rem 0" }} htmlFor={id}>
        <StyledCheckbox>
          {checked && <MdCheck color="#2d42ff" size={24} />}
        </StyledCheckbox>
        <span style={{ marginLeft: ".5rem", fontSize: "1rem" }}>{label}</span>
      </label>
    </>
  );
}
