import styled from "styled-components";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
  padding: 0.5rem;
  height: 200px;
  border: 0.125rem solid var(--gray-900);
  border-radius: 0.25rem;
  transition: background-color 0.2s;

  &.md {
    width: 16rem;
  }

  &.lg {
    width: 24rem;
  }

  &.employee {
    height: 140px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;

  figure {
    margin-right: 1rem;
  }
`;

export const UserTextData = styled.div`
  flex: 1;

  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 1.125rem;
    color: var(--blue-500);
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    font-size: 0.9rem;

    span {
      font-weight: bold;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  & > button:not(:last-of-type),
  & > a:not(:last-of-type) {
    margin-right: 0.25rem;
  }
`;
