import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Background = styled.div`
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 0.0625rem;
  background-color: var(--blue-500);
`;

export const EmployeeContainer = styled.section`
  background-color: var(--gray-50);
  padding: 0.5rem 1.5rem;
`;

export const EmployeeTitle = styled.h1`
  text-align: center;
  margin: 0 0 0.5rem;
`;

export const EmployeeCards = styled.div`
  max-height: 270px;
  overflow: auto;

  & > div:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`;
