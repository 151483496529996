import styled from "styled-components";

export const SearchTypeOption = styled.div`
  cursor: pointer;
  text-align: left;
  font-weight: bold;
  font-size: 1.25rem;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
  }
`;

export const CheckRadio = styled.div`
  cursor: pointer;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: var(--white);
  border: 0.125rem solid var(--blue-500);
  padding: 0.075rem;
  margin-right: 0.25rem;

  &.selected {
    & > div {
      display: block;
    }
    outline: solid 0.125rem var(--blue-200);
  }
`;

export const Bullet = styled.div`
  display: none;
  background-color: var(--blue-500);
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;
