import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  /* margin: 1rem 0; */
  padding: 0.5rem;
  background-color: var(--white);
`;

export const SearchBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  .table-search {
    width: 100%;
  }

  svg {
    position: absolute;
    right: 0.25rem;
    font-size: 1.5rem;
  }
`;

export const TableContent = styled.table`
  position: relative;
  margin-top: 1rem;
  width: 100%;

  thead {
    th {
      font-size: 1.25rem;
      background-color: var(--gray-200);
      color: var(--gray-50);
      padding: 0.5rem;
    }
  }

  tbody {
    tr:nth-child(2n + 1) {
      background-color: var(--blue-50);
    }

    td {
      padding: 0.25rem;
      text-align: center;
    }
  }
`;

export const ActionTd = styled.span`
  button {
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }

    &.icon {
      background-color: #0000;
      border: none;
      font-size: 1.75rem;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Foot = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  ul {
    display: flex;
    list-style-type: none;

    li {
      a {
        background-color: var(--blue-500);
        color: var(--gray-50);
        border-radius: 0.5rem;
        padding: 0.25rem 0.5rem;
        transition: opacity 0.2s;
        font-size: 1rem;
        font-weight: bold;
      }

      &:hover:not(.disabled) {
        a {
          opacity: 0.8;
        }
      }

      &.previous,
      &.next {
        a {
          padding: 0.25rem;
        }
      }

      &.selected {
        a {
          background-color: var(--gray-300);
        }
      }

      &.disabled {
        a {
          background-color: var(--blue-100);
          cursor: not-allowed;
        }
      }
    }

    li:not(:last-of-type) {
      a {
        margin-right: 0.5rem;
      }
    }
  }
`;
