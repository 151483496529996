import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    text-align: left;
    font-weight: bold;
    font-size: 1rem;
    color: var(--gray-700);
  }

  input {
    background-color: var(--white);
    padding-left: 0.25rem;
    font-size: 1rem;
    height: 2rem;
    border: 0.125rem solid var(--gray-900);
    border-radius: 0.25rem;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.md {
      min-width: 8rem;
    }

    &.lg {
      min-width: 12rem;
    }

    &.xl {
      min-width: 18rem;
    }

    &:focus {
      outline: 0.125rem solid var(--blue-200);
    }

    &.error {
      border-color: var(--red-700);
    }
  }

  small {
    color: var(--red-700);
  }
`;
