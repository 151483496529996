import styled from "styled-components";

export const Text = styled.p`
  animation: blink 3s infinite;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;

  &.flex-parent {
    align-self: center;
    justify-content: center;
  }

  &.not-flex-parent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes blink {
    0% {
      color: var(--blue-500);
    }

    25% {
      color: var(--blue-100);
    }

    50% {
      color: var(--blue-500);
    }

    75% {
      color: var(--blue-100);
    }

    100% {
      color: var(--blue-500);
    }
  }
`;
