import styled from "styled-components";

export const Container = styled.div`
  border-radius: 0.5rem;
  padding: 0.5rem;
  width: 14rem;
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 1rem;
    word-break: break-word;
  }

  & > div {
    margin-top: 0.25rem;
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #888;

    time {
      margin-left: auto;
    }
  }

  &.received {
    background-color: var(--gray-100);
    align-self: flex-start;
  }

  &.sent {
    background-color: var(--blue-50);
    align-self: flex-end;
  }
`;
