import styled from "styled-components";

export const Overlay = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.cover {
    z-index: 800;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: #000a;
  }
`;

export const Modal = styled.div`
  z-index: 800;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: var(--white);
  width: 100vw;
  max-width: 300px;
  border: 0.125rem solid var(--gray-400);
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 1rem;
`;

export const ModalHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 1.25rem;
  }
`;

export const HeadButtons = styled.div``;

export const HeadButton = styled.button`
  width: 1.25rem;
  height: 1.25rem;
  line-height: 0.625rem;
  background-color: var(--gray-100);
  border: 0.125rem solid var(--gray-200);
  font-size: 1rem;

  &:not(:last-of-type) {
    margin-right: 0.125rem;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;

  img {
    border: 0.125rem solid var(--gray-100);
    width: 10rem;
    height: 12rem;
  }
`;

export const CallModalInfo = styled.div`
  display: flex;

  p:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;

export const ModalFoot = styled.div`
  display: flex;
  justify-content: center;
`;

export const CallButton = styled.button`
  width: 4rem;
  height: 2.5rem;
  border: none;
  border-radius: 0.125rem;
  font-size: 2rem;
  color: var(--white);
  line-height: 1.25rem;
  transition: transform 0.1s, opacity 0.2s;

  &:active {
    transform: scale(0.95, 0.95);
  }

  &:hover {
    opacity: 0.8;
  }

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  &.end-call {
    background-color: var(--red-500);
  }

  &.mute {
    background-color: var(--gray-900);
  }

  &.mute-on {
    background-color: var(--gray-300);
  }

  &.volume-up {
    background-color: var(--blue-500);
  }

  &.answer-call {
    background-color: var(--green-500);
  }
`;

export const CaseContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 800;
  position: absolute;
  left: 0.625rem;
  bottom: 0.625rem;
  width: 3rem !important;
  height: 3rem;

  &.minimized {
    background-color: var(--blue-500);
    height: 4rem;
    width: 12rem !important;
    border-radius: 2rem;
    padding: 0.5rem;
  }
`;

export const Case = styled.div`
  width: 3rem !important;
  height: 3rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--blue-500);
  color: var(--white);
`;

export const CaseOnCallLabel = styled.p`
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
`;
