import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--gray-100);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginBox = styled.main`
  width: 100%;
  max-width: 360px;
  margin: 0 1rem;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: var(--gray-50);
  box-shadow: -4px 4px 15px -5px var(--gray-400);

  h1 {
    color: var(--blue-500);
    font-size: 1.5rem;
  }

  h3 {
    color: var(--blue-500);
    font-size: 0.75rem;
    margin-bottom: 1.5rem;
  }
`;

export const PasswordContainer = styled.div`
  position: relative;
`;

export const PasswordVisibleButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(-50%);
  width: fit-content;
  height: fit-content;
  width: 20;
  height: 20;
  background-color: #0000;
  border: none;
`;

export const InputsContainer = styled.div`
  & > div {
    margin-bottom: 0.25rem;
  }
`;

export const ForgetPassword = styled.p`
  & > button {
    background-color: none;
    text-decoration: underline;
    color: var(--blue-500);
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: center;
`;
